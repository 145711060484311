import React, { useState } from "react"
import { Project } from "./projects"
import projects from "./projects.json"
import parse from "html-react-parser"
import { Status } from "./status"
import Button from "./button"

export interface Session {
  session: string
  eo: string
  project: string
  volunteer: string
  description: string
  short: string
  defaultImage?: string
  funnyImage?: string
  link: string
}

export function Session({
  session,
  status,
  setZoomLink,
}: {
  session: Session
  status: Status
  setZoomLink: (link: string) => void
}) {
  const [extended, setExtended] = useState(false)
  const project = projects.find((p) => p.id === session.project)
  return (
    <div className="relative rounded-lg overflow-hidden bg-white shadow-md m-2">
      {session.project === "unknown" && (
        <div className="absolute h-full w-full bg-opacity-50 bg-gray-400 z-10">
          <div className="text-red-600 font-bold text-4xl tracking-widest transform rotate-45 -translate-x-1/2 -translate-y-1/2 ml-1/2 mt-3/4 bottom-0 mb-3/4 mx-auto break-normal max-w-full">
            <p className="-mx-10">entfällt</p>{" "}
            <p className="ml-10 mr-56">leider</p>
          </div>
        </div>
      )}

      <div className="rounded-t-lg overflow-hidden">
        <div className="relative pb-3/4 text-6xl ">
          <img
            className="absolute h-full w-full object-cover"
            src={
              session.funnyImage || session.defaultImage || "img/missing.jpeg"
            }
            alt="Lustiges Bild"
          />
          <img
            className="absolute h-full w-full object-cover transition-opacity duration-500 ease-in-out hover:opacity-0"
            src={session.defaultImage || "img/missing.jpeg"}
            alt="Bild zum Vortrag"
          />
          <img
            className="absolute right-0 mr-2 bottom-0 -mb-8 w-2/12"
            src={`img/flags/${project?.country}.svg`}
            alt=""
          />
        </div>
        <div className="px-2 font-bold text-l text-blue-800 mr-10 mt-2 leading-none">
          {session.volunteer?.trim() || "Wird noch bekannt gegeben"}
        </div>
        <div className="px-2 text-sm mr-10">
          {" "}
          {session.short?.trim() || "–"}{" "}
        </div>
        <div>
          <Project className="m-1 my-2" id={session.project} />
          <Button
            enabled={status === "open"}
            onClick={() => setZoomLink(session.link)}
          >
            Teilnehmen
          </Button>
        </div>
      </div>

      <hr className="clear-both" />

      <div className="m-4 clear-both">
        <div className="clear-both prose lg:prose-xl text-sm text-justify">
          {extended ? (
            <div className="text text-justify mb-6">
              {parse(
                session.description?.trim() ||
                  "Weitere Informationen werden später noch hinzugefügt.",
              )}
            </div>
          ) : (
            <>
              <div className="text relative h-16 overflow-hidden text-justify">
                {parse(
                  session.description?.trim() ||
                    "Weitere Informationen werden später noch hinzugefügt.",
                )}
                <div
                  className="absolute bottom-0 w-full h-12"
                  style={{
                    backgroundImage: "linear-gradient(to bottom, #fff0, white)",
                  }}
                />
              </div>
            </>
          )}
        </div>
        {session.description && (
          <button
            className="mb-4 mx-2 h-8 col-auto bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white border border-blue-500 hover:border-transparent rounded"
            onClick={() => setExtended(!extended)}
          >
            <span className="align-middle py-1 px-4">
              {extended ? "Weniger Info" : "Mehr Info"}
            </span>
          </button>
        )}
      </div>
    </div>
  )
}
