import { Session } from "./session"
import React, { useContext } from "react"
import { TimeContext } from "./App"
import moment, { Moment } from "moment"
import "moment/locale/de"
import { getStatus, Status } from "./status"

moment.locale("de")

interface SessionBlock {
  time: { start: string; end: string }
  title: string
  sessions: Session[]
  setZoomLink: (link: string) => void
}

function formatDuration(ms: number, format: string) {
  const days = Math.floor(ms / 8.64e7)
  const msOnLastDay = ms - days * 8.64e7
  return moment.utc(msOnLastDay).format(format)
}

function readableDistance(first: Moment, second: Moment) {
  const distance = moment.duration(first.diff(second))

  if (Math.floor(distance.asDays()) > 0)
    return Math.floor(distance.asDays()) + " Tage"

  if (Math.floor(distance.asHours()) > 0)
    return formatDuration(distance.asMilliseconds(), "HH:mm:ss")

  if (Math.floor(distance.asMinutes()) > 0)
    return formatDuration(distance.asMilliseconds(), "mm:ss")

  return formatDuration(distance.asMilliseconds(), "ss") + " Sekunden"
}

function Einlass(props: { status: Status; start: Moment; local: Moment }) {
  switch (props.status) {
    case "open": {
      const rest = moment.duration(
        props.start.clone().add(2, "minutes").diff(props.local),
      )
      if (rest.asSeconds() > 60) {
        return (
          <span className="text-green-300 text-l">
            [ offen! - jetzt eintreten ]
          </span>
        )
      } else {
        return (
          <span className="text-green-300 text-l">
            [ noch{" "}
            <span className="font-mono">
              {formatDuration(rest.asMilliseconds(), "ss")}
            </span>{" "}
            Sekunden offen! ]
          </span>
        )
      }
    }
    case "running":
      return (
        <span className="text-red-900 text-l">[ Session läuft bereits ]</span>
      )
    case "past":
      return (
        <span className="text-red-900 text-l">
          [ Session wurde bereits beendet ]
        </span>
      )
    case "future":
      return (
        <span className="text-l">
          [ Einlass von{" "}
          {props.start.clone().subtract(5, "minute").format("HH:mm")} bis{" "}
          {props.start.format("HH:mm")} ]
        </span>
      )
  }
}

function SessionBlock({ sessions, title, time, setZoomLink }: SessionBlock) {
  const localTime = moment(useContext(TimeContext))
  const start = moment(time.start)
  const end = moment(time.end)
  const status = getStatus(start, localTime)

  return (
    <div
      className={
        "grid w-full bg-indigo-100 lg:border rounded-t-md pb-2 overflow-hidden mb-10" +
        (status === "open" || status === "running" ? " shadow-outline" : "") +
        (status === "past" || status === "running"
          ? " opacity-50 pointer-events-none"
          : "")
      }
    >
      <div
        className={
          "bg-teal-500 text-gray-100 px-5" +
          (status === "open" || status == "running" ? " bg-blue-500" : "")
        }
      >
        {status === "future" && (
          <span className="float-right mr-10 align-middle hidden sm:block">
            noch{" "}
            <span className="font-mono">
              {readableDistance(start.clone().subtract(5, "minute"), localTime)}{" "}
            </span>
            bis zum Einlass
          </span>
        )}
        <div>
          <span className="pr-2 sm:pr-4 text-xl font-extrabold tracking-wider border-r-2">
            {title}
          </span>
          <span className="sm:pl-2 lg:pl-4 pr-4 text-l font-normal tracking-wider align-baseline ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              className="text-white fill-current h-3 align-baseline px-2 inline-block"
            >
              <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z" />
            </svg>
            {`${start.format("HH:mm")}–${end.format("HH:mm")}`}
          </span>
          <br />
          <Einlass status={status} start={start} local={localTime} />
        </div>
      </div>
      <div className="mx-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
        {sessions.map((session, idx) => (
          <Session
            setZoomLink={setZoomLink}
            session={session}
            key={session.link + "_" + idx}
            status={status}
          />
        ))}
      </div>
    </div>
  )
}

export default SessionBlock
