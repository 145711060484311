import React, { useContext, useMemo } from "react"
import SessionBlock from "./session-block"
import program from "./program.json"
import { Session } from "./session"
import Button from "./button"
import { TimeContext } from "./App"
import moment from "moment"

interface Block {
  start: string
  end: string
  sessions: Session[]
}

function statusFor(
  time: moment.Moment,
  start: moment.Moment,
  end: moment.Moment,
) {
  if (time.isBefore(start)) return "future"
  if (time.isAfter(end)) return "closed"
  return "open"
}

export default function Sessions({
  sessions,
  setZoomLink,
}: {
  sessions: Session[]
  setZoomLink: (link: string) => void
}) {
  const blocks: Block[] = useMemo(
    () =>
      program.map(({ start, end, sessions: blockSessions }) => ({
        start,
        end,
        sessions: blockSessions
          .map((session) => sessions.find((s) => s.session === session))
          .filter((s): s is Session => !!s),
      })),
    [sessions],
  )

  const localeTime = useContext(TimeContext)
  const statusUta = statusFor(
    moment(localeTime),
    moment("2023-11-30T17:40Z"),
    moment("2023-11-30T20:45Z"),
  )

  return (
    <div className="p-4">
      <div
        className={
          "grid w-full bg-indigo-100 lg:border rounded-t-md pb-2 overflow-hidden mb-10" +
          (statusUta === "open" ? " shadow-outline" : "") +
          (statusUta === "closed" ? " opacity-50 pointer-events-none" : "")
        }
      >
        <div
          className={
            "text-gray-100 " +
            (statusUta === "open" ? "bg-blue-500" : "bg-teal-500")
          }
        >
          <span className="pl-4 sm:pl-6 lg:pl-8 pr-4 text-xl font-extrabold tracking-wider sm:border-r-2">
            Allgemeine Informationen
          </span>
          <span className="pl-2 lg:pl-4 pr-4  text-xl font-normal tracking-wider">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              className="text-white fill-current h-4 align-baseline px-3 inline-block"
            >
              <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z" />
            </svg>
            18:45 – 21:30
          </span>
        </div>

        <div>
          <div className="mx-2 grid grid-cols-1">
            <div className="rounded-lg overflow-hidden bg-white shadow-md m-2">
              <div className="rounded-t-lg overflow-hidden p-4 grid grid-flow-col">
                <div className="">
                  <img
                    alt="Uta Hillermann"
                    className="sm:float-left lg:mb-32 mb-4 h-32 mr-10 rounded-lg"
                    src="img/hillermann.jpg"
                  />
                  <div className="pb-1">
                    Du hast Fragen, was genau es heißt, einen Freiwilligendienst
                    zu leisten? Du möchtest wissen, was denn eigentlich der SDFV
                    ist oder Du möchtest einfach nur nochmal testen, ob Deine
                    Technik einwandfrei funktioniert? Dann schalte Dich gerne
                    jeder Zeit zu unserem „zoom-room“ für allgemeine
                    Informationen zu!
                  </div>
                  <p>
                    Hier triffst Du{" "}
                    <strong className="font-semibold">Uta Hillermann</strong>,
                    die als Pädagogische Mitarbeiterin bestens über den SDFV und
                    die Auslandsfreiwilligendienste bescheid weiß. Außerdem ist
                    sie die erste Adresse, wenn es um Themen rund um die
                    Finanzierung eines Freiwilligendienstes, Versicherungen, das
                    Förderprogramm „weltwärts“ oder einen Überblick über die
                    einzelnen Projekte geht! Auch Deine Eltern oder Angehörigen
                    sind herzlich eingeladen, hier ihre Fragen loszuwerden.
                  </p>
                  <div className="my-auto mx-auto col-span-1 sm:col-span-2 lg:col-span-1 p-5">
                    <Button
                      enabled={statusUta === "open"}
                      onClick={() =>
                        setZoomLink(
                          "https://eu01web.zoom.us/j/69470203197?pwd=V3JKSHVzMUNSeVdpc3p4SWJnczNJQT09",
                        )
                      }
                    >
                      Eintreten
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {blocks.map((block, i) => (
        <SessionBlock
          sessions={block.sessions as Session[]}
          time={{ ...block }}
          title={`${i + 1}. Session`}
          setZoomLink={setZoomLink}
          key={`block-${i}`}
        />
      ))}
    </div>
  )
}
