import { WorldMap } from "./world-map"
import React, { useState } from "react"
import EO, { EOCode } from "./organisation"
import { useMediaQuery } from "react-responsive"
import projects from "./projects.json"

export type CountryCode =
  | "ba"
  | "co"
  | "ec"
  | "bo"
  | "pl"
  | "mk"
  | "ip"
  | "gh"
  | "zm"
  | "xk"
  | "in"

interface CountryProps {
  countryCode: CountryCode
  children: React.ReactNode
  selected: CountryCode | undefined
  onSelect: (c: CountryCode | undefined) => void
}

function Country({ countryCode, children, selected, onSelect }: CountryProps) {
  return (
    <div
      className={
        "flex justify-start border-b-2 last:border-0 flex-grow mb-1 pb-1" +
        (selected === countryCode ? "  bg-indigo-100 bg" : "")
      }
      onMouseEnter={() => onSelect(countryCode)}
      onMouseLeave={() => onSelect(undefined)}
    >
      <img
        src={`img/flags/${countryCode}.svg`}
        alt="Landesflagge"
        className={
          "h-8 w-8 col-start-1 align-top m-1 mr-2 border rounded-full border-gray-400"
        }
      />
      <div className="flex-grow">{children}</div>
    </div>
  )
}

export function Project({
  id,
  className,
}: {
  id: string
  className?: string
}): JSX.Element {
  const project: ProjectData = projects.find((p) => p.id === id) as ProjectData
  return (
    <div className={"flex justify-start " + className ?? ""}>
      <EO
        code={project.eo}
        className="w-12 h-12 hover:bg-indigo-200 p-1 cursor-pointer"
      />
      <div
        className="hover:bg-indigo-200 p-1 px-2 cursor-pointer min-w-0 flex-grow"
        onClick={() => window.open(project.link, "_blank")}
      >
        <p className="text-xs leading-none truncate">{project.topic}</p>
        <p className="leading-5 truncate">{project.organisation}</p>
        <p className="text-xs leading-none truncate">{project.place}</p>
      </div>
    </div>
  )
}

function Latin({
  selected,
  setSelected,
}: {
  selected: CountryCode | undefined
  setSelected: (c: CountryCode | undefined) => void
}) {
  return (
    <>
      <Country countryCode="co" selected={selected} onSelect={setSelected}>
        <Project id="concern" className="border-b" />
        <Project id="hogar" className="border-b" />
        <Project id="suenos" className="border-b" />
        <Project id="colectivos" />
      </Country>

      <Country countryCode="ec" selected={selected} onSelect={setSelected}>
        <Project id="arbol" className="border-b" />
      </Country>

      <Country countryCode="bo" selected={selected} onSelect={setSelected}>
        <Project id="kurmi" className="border-b" />
        <Project id="yanapasayku" />
      </Country>
    </>
  )
}

function Europe({
  selected,
  setSelected,
}: {
  selected: CountryCode | undefined
  setSelected: (c: CountryCode | undefined) => void
}) {
  return (
    <>
      <Country countryCode="pl" selected={selected} onSelect={setSelected}>
        <Project id="dialog" />
      </Country>
      <Country countryCode="xk" selected={selected} onSelect={setSelected}>
        <Project id="integra" />
      </Country>
      <Country countryCode="ba" selected={selected} onSelect={setSelected}>
        <Project id="zfd" />
      </Country>
    </>
  )
}

function Africa({
  selected,
  setSelected,
}: {
  selected: CountryCode | undefined
  setSelected: (c: CountryCode | undefined) => void
}) {
  return (
    <>
      <Country countryCode="gh" selected={selected} onSelect={setSelected}>
        <Project id="rays" />
      </Country>
    </>
  )
}

function Asia({
  selected,
  setSelected,
}: {
  selected: CountryCode | undefined
  setSelected: (c: CountryCode | undefined) => void
}) {
  return (
    <>
      <Country countryCode="in" selected={selected} onSelect={setSelected}>
        <Project id="diksha" />
        <Project id="aseema" />
      </Country>
    </>
  )
}

export default function Projects(): JSX.Element {
  const [selected, setSelected] = useState<CountryCode | undefined>(undefined)
  const inMap = useMediaQuery({ query: "(min-width: 1024px)" })
  const twoColumn = useMediaQuery({ query: "(min-width: 640px)" })

  if (inMap) {
    return (
      <div className="relative pb-1/2">
        <WorldMap
          className="w-full absolute"
          selected={selected}
          onSelect={setSelected}
        />
        <div className="absolute bg-gray-100 bg-opacity-75 border shadow-lg rounded-lg p-1 bottom-0 xl:mb-1/12 xl:mr-9/12 xl:right-0 mb-5 ml-5 grid grid-flow-row">
          <Latin selected={selected} setSelected={setSelected} />
        </div>

        <div className="absolute bg-gray-100 bg-opacity-75 border shadow-lg rounded-lg p-1 xl:bottom-0 right-0 mr-5 mb-1/3 xl:ml-8/12 xl:left-0 xl:right-auto grid grid-flow-row">
          <Europe selected={selected} setSelected={setSelected} />
        </div>

        <div className="absolute bg-gray-100 bg-opacity-75 border shadow-lg rounded-lg p-1 mb-1/12 -bottom-16 mb-4 xl:ml-6/12 md:ml-5/12 -left-16 ml-4/12 grid grid-flow-row">
          <Africa selected={selected} setSelected={setSelected} />
        </div>

        <div className="absolute bg-gray-100 bg-opacity-75 border shadow-lg rounded-lg p-1 -right-12 mb-1/12 mr-1/12 sm:bottom-5 grid grid-flow-row">
          <Asia selected={selected} setSelected={setSelected} />
        </div>
      </div>
    )
  } else {
    return (
      <>
        <WorldMap
          className="w-full -ml-1/12"
          selected={selected}
          onSelect={setSelected}
        />
        <div className="grid m-2">
          <div className="col-start-1 row-span-2 m-1 bg-gray-100 bg-opacity-75 border shadow-lg rounded-lg p-1">
            <h1 className="p-2"> Südamerika </h1>
            <Latin selected={selected} setSelected={setSelected} />
          </div>
          <div
            className={`col-start-${
              twoColumn ? 2 : 1
            } m-1 bg-gray-100 bg-opacity-75 border shadow-lg rounded-lg p-1`}
          >
            <h1 className="p-2"> Europa </h1>
            <Europe selected={selected} setSelected={setSelected} />
          </div>
          <div
            className={`col-start-${
              twoColumn ? 2 : 1
            } m-1 bg-gray-100 bg-opacity-75 border shadow-lg rounded-lg p-1`}
          >
            <h1 className="p-2"> Afrika </h1>
            <Africa selected={selected} setSelected={setSelected} />
          </div>
          <div
            className={`col-start-${
              twoColumn ? 2 : 1
            } m-1 bg-gray-100 bg-opacity-75 border shadow-lg rounded-lg p-1`}
          >
            <h1 className="p-2"> Asien </h1>
            <Asia selected={selected} setSelected={setSelected} />
          </div>
        </div>
      </>
    )
  }
}

interface ProjectData {
  eo: EOCode
  topic: string
  organisation: string
  place: string
  link: string
}
