import { Moment } from "moment"

export type Status = "open" | "future" | "past" | "running"

export function getStatus(start: Moment, locale: Moment): Status {
  const enterStart = start.clone().subtract(5, "minute")
  const enterEnd = start.clone().add(20, "minute")
  const end = start.clone().add(25, "minute")
  if (locale.isBefore(enterStart)) return "future"
  if (locale.isBetween(enterStart, enterEnd)) return "open"
  if (locale.isBetween(enterEnd, end)) return "running"
  return "past"
}
